export default [
    {
        path: '/transactions',
        name: 'payout-history-list',
        component: () => import('@/views/payout/HistoryList.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Payout history',
                    active: true
                }
            ],
            pageTitle: 'Payout history',
            navActiveLink: 'payout-history-list',
            resource: 'Auth',
            action: 'manage',
        },
    },
    {
        path: '/bookingtransactions',
        name: 'bookingtransactions-list',
        component: () => import('@/views/bookingtransactions/TransactionsList'),
        meta: {
            breadcrumb: [
                {
                    text: 'Credits history',
                    active: true
                }
            ],
            pageTitle: 'Credits history',
            navActiveLink: 'bookingtransactions-list',
            resource: 'Auth',
            action: 'manage',
        },
    },
]


