import axios from "@/libs/axios";
import {isUserLoggedIn} from "@/auth/utils";

const state = {
    user: null,
}

const mutations = {
    SET_USER: (state, user) => {
        state.user = user;
    },
}

const actions = {
    GET_USER: (context) => {
        const isLoggedIn = isUserLoggedIn()

        if (isLoggedIn) {
            return axios.get('/affiliates/profile').then(response => {
                context.commit('SET_USER', response.data);
            })
        }
    },
}

const getters = {
    USER: state => {
        return state.user
    },
}

export default {
    state, getters, mutations, actions
}
