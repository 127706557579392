<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isHelpSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-help-sidebar-active', val)"
    >
      <template>
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
        <!-- Body -->
        <div class="mt-1" v-html="content"></div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import { BSidebar } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { EventBus } from '@/@core/mixins/eventBus'
export default {
  components: {
    // BSV
    BSidebar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isHelpSidebarActive",
    event: "update:is-help-sidebar-active",
  },
  props: {
    isHelpSidebarActive: {
      type: Boolean,
      required: true,
    },
    slug: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "",
      content: "",
      locale: this.$i18n.locale
    };
  },
  watch: {
    slug: function () {
      this.getItem();
    },
  },
  mounted() {
    this.getItem();
    EventBus.$on("localeObj", (data) => {
      this.locale = data;
      this.getItem();
    });
  },
  methods: {
    getItem() {
      this.$http.get(`/helpareacontent/affiliate/${this.slug}`).then((res) => {
        if (this.locale == "de") {
          this.title = res.data.item.title_german;
          this.content = res.data.item.content_german;
        } else {
          this.title = res.data.item.title_english
            ? res.data.item.title_english
            : res.data.item.title_german;
          this.content = res.data.item.content_english
            ? res.data.item.content_english
            : res.data.item.content_english;
        }
      });
    },
  },
};
</script>
<style>
.b-sidebar-body img {
  max-width: 100%;
}
.b-sidebar-body {
  padding: 10px;
}
</style>