import Vue from 'vue'

Vue.mixin({
  methods: {
    replaceBreadcrumbsPlaceholders(data) {
      let routeParams = this.$route.params
      let self = this

      Object.entries(routeParams).forEach(routeParam => {
        const [key, value] = routeParam;

        this.$route.meta.breadcrumb.forEach(breadcrumbItem => {
          if ("to" in breadcrumbItem) {
            breadcrumbItem.to = breadcrumbItem.to.replace(key, value)
          }
        })
      });

      data.forEach(item => {
        this.$route.meta.breadcrumb.forEach(breadcrumbItem => {
          if ("to" in breadcrumbItem) {
            breadcrumbItem.to = breadcrumbItem.to.replace(item.key, item.value)
          }
          if ("text" in breadcrumbItem) {
            if (!("textoriginal" in breadcrumbItem)) {
              breadcrumbItem.textoriginal = breadcrumbItem.text.slice()
            }

            breadcrumbItem.text = breadcrumbItem.textoriginal.slice().replace(item.key, item.value)
          }
        })

        if (!("pageTitleOriginal" in this.$route.meta)) {
          this.$route.meta.pageTitleOriginal = this.$route.meta.pageTitle.slice()
        }
        this.$route.meta.pageTitle = this.$route.meta.pageTitleOriginal.slice().replace(item.key, item.value)
      })

      this.$router.replace({ query: { temp: Date.now() } })
      this.$router.replace({ query: { temp: undefined } })
    },
    resetBreadcrumbData() {
      if ("pageTitle" in this.$route.meta && "pageTitleOriginal" in this.$route.meta) {
        this.$route.meta.pageTitle = this.$route.meta.pageTitleOriginal
      }

      this.$route.meta.breadcrumb.forEach(breadcrumbItem => {
        if ("to" in breadcrumbItem && "tooriginal" in breadcrumbItem) {
          breadcrumbItem.to = breadcrumbItem.tooriginal
        }
        if ("text" in breadcrumbItem && "textoriginal" in breadcrumbItem) {
          breadcrumbItem.text = breadcrumbItem.textoriginal
        }

        this.$router.replace({ query: { temp: Date.now() } })
        this.$router.replace({ query: { temp: undefined } })
      })
    },
    addBreadcrumbDataFromRouteParams(params) {
      if ("breadcrumbdata" in this.$route.params) {
        this.breadcrumbData = this.$route.params.breadcrumbdata
      }

      this.resetBreadcrumbData()

      Object.entries(params).forEach(routeParam => {
        const [key, value] = routeParam;

        if (key == "breadcrumbdata") {
          this.breadcrumbData = value
          this.replaceBreadcrumbsPlaceholders(value)
        }

        this.$route.meta.breadcrumb.forEach(breadcrumbItem => {
          if ("to" in breadcrumbItem) {
            breadcrumbItem.to = breadcrumbItem.to.replace(key, value)
          }
        })
      });
    },
    addBreadcrumbDataFromUrl(url, data) {
      this.$http.get(url)
        .then(response => {
          data.forEach(item => {
            data.value = _.get(response.data, data.path);
          })

          this.replaceBreadcrumbsPlaceholders(data)
        })
        .catch(error => {
        })
    },
  },
})
