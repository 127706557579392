import axios from "@/libs/axios";

const state = {
    balance: 0,
}

const mutations = {
    SET_BALANCE: (state, balance) => {
        state.balance = balance;
    },
}

const actions = {
    GET_BALANCE: (context) => {
        return axios.get('/affiliates/balance').then(response => {
            context.commit('SET_BALANCE', response.data.totalbalance);
        })
    },
}

const getters = {
    BALANCE: state => {
        return state.balance
    },
}

export default {
    state, getters, mutations, actions
}
