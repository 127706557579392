import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import paymentmethods from './routes/payment-methods'
import notifications from '@/router/routes/notifications'
import transactions from '@/router/routes/transactions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'dashboard'}},
        ...dashboard,
        ...pages,
        ...notifications,
        ...paymentmethods,
        ...transactions,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({name: 'auth-login'})

        // If logged in => not authorized
        return next({name: 'misc-not-authorized'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')

    if (appLoading) {
        if (to.name === "apps-bookings-futurebookings-details") {
            setTimeout(function () {
                appLoading.style.display = 'none'
            }, 1200)
        } else {

            appLoading.style.display = 'none'
        }
    }
})

export default router
