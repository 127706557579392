export default [

// Notifications list
  {
    path: '/notifications',
    name: 'notifications-list',
    component: () => import('@/views/pages/notifications/NotificationList.vue'),
    meta: {
      breadcrumb: [{
        text: 'Notifications List',
        to: '/apps/notifications',
        active: true
      }, ],
      pageTitle: 'Notifications List',
      navActiveLink: 'apps-notifications-list',
    },
  },

// Notification details
  {
    path: '/notifications/details',
    name: 'notification-preview',
    component: () => import('@/views/pages/notifications/NotificationPreview.vue'),
    meta: {
      breadcrumb: [{
        text: 'Notifications Details',
        to: '/notifications',
        active: true
      }, ],
      pageTitle: 'Notifications Details',
      navActiveLink: 'apps-notifications-list',
    },
  },

]
